/* Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
   product_right.php, wishlist_index.php */

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;

function restoreWishlistButtonState() {
    $("#addToWishlistButton span").html(button_add_to_wishlist_initial_label);
    $("#addToWishlistButton").removeClass("existToWishlistButton").addClass("addToWishlistButton");
    $("#addToWishlistButton").attr("onclick", button_add_to_wishlist_initial_click_event).attr("href", "#");
}

/* Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php) */
function addToWishlist(evt) {
    var product_id = $("#hdn_product_id").val();
    var product_ref = $("#product_refext").val();
    var is_model = $("#hdn_is_model").val();

    // On regarde si le produit est deja dans la wishlist
    if (!checkProductInWishlist()) {
        evt.preventDefault();

        if (typeof (button_add_to_wishlist_initial_click_event) == "undefined") {
            button_add_to_wishlist_initial_click_event = $("#addToWishlistButton").attr("onclick");
        }

        if (typeof (button_add_to_wishlist_initial_label) == "undefined") {

            button_add_to_wishlist_initial_label = $("#addToWishlistButton span").html();
        }

        if (!product_id || typeof product_id == "undefined") {

            return false;
        }

        var color_id = $("#couleurProd").val();
        var size_id = $("#tailleProd").val();
        var need_color_id = ($("#couleurProd").length != 0 && $("#couleurProd").val() != 'none');
        var need_size_id = ($("#tailleProd").length != 0 && $("#tailleProd").val() != 'none');

        var data = {}

        data.product_id = product_id;
        data.size_id = size_id;
        data.color_id = color_id;
        data.need_color_id = need_color_id;
        data.need_size_id = need_size_id;
        data.is_model = is_model;

        var expressBuy = $("#lightbox_achat_express").length != 0;

        if (document.getElementById('lightbox_achat_express'))
            from_range = true;
        else
            from_range = false;

        $.post(path_relative_root + 'ajax_add_to_wishlist.php?time=' + Date.now(), data, function (resp) {

            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox")

            $("#addToWishlistButton").parent().removeClass('loading');

            if (resp.error) {
                if ($alertboxLogin.length == 0) {
                    $alertboxLogin = $(
                        '<div id="abox">' + html + "</div>"
                    );
                }

                if (resp.message == "login_required") {
                    if ($('#abox .btn_container.btn_cnx').length == 0) {
                        $alertboxBtns
                            .append($(
                                '<div class="btn_container btn_cnx">' +
                                '<button class="button white"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + data.product_id + '\');">' +
                                '<span>' +
                                resp.result.btn_connexion +
                                '</span>' +
                                '</button>' +
                                '</div>'));
                        $alertboxLogin
                            .find(".txt_alert")
                            .html(
                                '<div class="box_wishlist">' +
                                resp.result.error_message +
                                '</div>'
                            );

                    }

                    $('#abox .form_submit').css('display', 'none');
                    $alertboxLogin.addClass('fromWishlist');
                    openMultiShad('abox');
                    $('.shad').addClass('actif');
                }
            } else {
                $("#addToWishlistButton span").html(resp.result.button_label);
                $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");
                $("#addToWishlistButton").on('click', function(){
                    window.location.replace(path_relative_root + create_link('wishlist'));
                });

                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'addToWishlist',
                        'products': {
                            'product_id': product_id,
                            'product_ref': product_ref,
                        }
                    });
                }

                wishlist.push(resp.result.wishlist_row_array);

                addToWishlistUpdateHeader(resp.result.wishlist_size);
            }
        });
    }
}

function addWishListNoConnect(prod_id) {
    $.post(
        path_relative_root + "ajax_add_to_wishlist.php?time=" + Date.now(),

        function (resp) {
            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox")

            var html =
                '<div class="popup_title"></div>';

            if (resp.error) {
                if ($alertboxLogin.length == 0) {
                    $alertboxLogin = $(
                        '<div id="abox">' + html + "</div>"
                    );
                }

                if (resp.message == "login_required") {
                    if ($('#abox .btn_container.btn_cnx').length == 0) {
                        $alertboxBtns
                            .append($(
                                '<div class="btn_container btn_cnx">' +
                                '<button class="button white"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + prod_id + '\');">' +
                                '<span>' +
                                resp.result.btn_connexion +
                                '</span>' +
                                '</button>' +
                                '</div>')
                            );
                        $alertboxLogin
                            .find(".txt_alert")
                            .html(
                                '<div class="box_wishlist">' +
                                resp.result.error_message +
                                '</div>'
                            );
                    }

                    $('#abox .form_submit').css('display', 'none');
                    $alertBox = $alertboxLogin;
                }

                $alertboxLogin.addClass('fromWishlist');
                openMultiShad('abox');

            } else {
                $("span", btn).html(resp.result.button_label);
                btn.removeClass("addToWishlistButton").addClass(
                    "existToWishlistButton"
                );
                btn.attr("onclick", "");
                if (typeof isWeddingSpace == "undefined") {
                    btn.attr("href", resp.result.button_link);
                }

                wishlist.push(resp.result.wishlist_row_array);
                addToWishlistUpdateHeader(resp.result.wishlist_size);
            }
        }
    )
}

function addWishNoConnect(div_id, user, reference, is_alliance, code_couleur, idprod, wishlist) {

    if (user) {

        ajax_achat_express(reference, code_couleur, idprod, wishlist);
    } else {

        addWishListNoConnect(div_id);
    }
}


function checkProductInWishlist() {
    var prod_id = $("#produit_id").val();
    var couleurProd = $("#couleurProd");
    if (couleurProd.length) {
        couleurProd = $("#couleurProd").val();
    } else {
        couleurProd = 0;
    }
    var tailleProd = $("#tailleProd");
    if (tailleProd.length) {
        tailleProd = $("#tailleProd").val();
    } else {
        tailleProd = 0;
    }

    if (typeof prod_id != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data:{'product_id': prod_id, 'size_id': tailleProd, 'color_id': couleurProd},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

/* Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
   Aurelien Renault */
function changeBtnAddWishlist(in_wishlist) {
    var btn = $("#addToWishlistButton");
    if (in_wishlist) {
        btn.attr("onclick", "");
        btn.attr("href", path_relative_root + create_link('wishlist'));
        $("#addToWishlistButton span").html(translate('exist_to_wishlist_button_label'));
        btn.removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        btn.attr("onclick", button_add_to_wishlist_initial_click_event);
        btn.attr("href", "javascript:;");
        $("#addToWishlistButton span").html(Translator.translate('add_wishlist'));
        btn.removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

function wishlistRedirectLogin(div_id) {
    $.get(path_relative_root + "ajax_add_to_wishlist.php?login_required=1", function () {
        document.location.href = path_relative_root + create_link('connexion_login') + "?from=front&div_id=" + div_id;
    });
}

function addToWishlistUpdateHeader(nr) {
    $(".btn_top_wishlist .gris").html('(' + nr + ')');

    if (nr == 0) {
        $("#basket1").show();
        $("#basket2").hide();
        $(".btn_top_wishlist").hide();
        $("#number_wishlist_top").html('(0)');
    } else {
        $("#whishlist").css({'display': 'block'});
        $("#basket2").show();
        $("#basket1").hide();
        $(".btn_top_wishlist").show();
        $("#number_wishlist_top").html('(' + nr + ')');
    }
}

function scanForChanges() {
    var totalPrice = 0;
    totalPoints = 0;
    selectedProducts = new Array();
    $(".checkbox_select_product").each(function () {
        var element = $(this);
        var id = element.attr("data-id");

        if (element.is(':checked')) {
            var price = parseFloat(element.attr('data-price'));

            if (price) {
                totalPrice += price;
            }

            selectedProducts.push(id);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }
        }
    });
    var txt = "",
        text = "",
        pricefloor,
        decimal;
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + ' :';
            if (lang_iso == 'en') {
                priceSpan.html('<span id="total_price">' + siteCurrency.symbol_left + '0</span><sup></sup><span class="decimal_price"></span></span>');
            } else {
                priceSpan.html('<span id="total_price">0<span class="devise">&euro;</span><sup></sup><span class="decimal_price"></span></span>');
            }

            break;
        case 1:
            text = $texts[1];
        default:
            if (text == '')
                text = $texts[2];

            pricefloor = Math.floor(totalPrice);

            if (pricefloor == totalPrice) {
                decimal = '';
            } else {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = '' + decimal;
                if (decimal.length == 1) {
                    decimal = decimal + '0';
                }
            }
            if (lang_iso == 'en') {
                priceSpan.html('<span id="total_price">' + siteCurrency.symbol_left  + pricefloor + '<span class="devise"></span><sup></sup><span class="decimal_price">' + decimal + '</span></span>');
            } else {
                priceSpan.html('<span id="total_price">' + pricefloor + '<span class="devise">&euro;</span><sup></sup><span class="decimal_price">' + decimal + '</span></span>');
            }

            txt = '<span class="wishlist_count">' + selectedProducts.length + '</span> ' + text + ' :';
    }

    $(".txt", "#nrArticlesSelected").html(txt);

}

function addProductToCart(wpId, pId, sId, cId) {
    $.post(path_relative_root + create_link('ajax_wishlist_add_product_to_cart'),
        {
            product_id: pId,
            size_id: sId,
            color_id: cId
        }, function (resp) {
            if (resp == "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
            } else {
                if ($("#ckwp_" + wpId).attr("pointscadeau")) {
                    alert(translate('err_no_points'));
                }
            }
        });
}

/* Ferme une popup par rapport à son nom et ferme aussi le shad
   Utilisation : #sendwishlistbox qui est généré dinamiquement dans wishlist_index.php */
function closePopup(popup_id, shad_id) {
    if (shad_id == undefined) {
        shad_id = 'shad';
    }
    var $popup = $('#' + popup_id),
        $shad = $('#' + shad_id);

    $shad.unbind('click.popup');
    $popup.slideUp('slow', function () {
        $shad.fadeOut('slow', function () {
            if ($('#wrapper_sendwishlist').hasClass('sended')) {
                $('#sendwishlistbox').remove();
            }
        });
    });
}

/* Affiche la lighbox de la wishlist, dynamiquement si la box n'existe pas
   Utilisation : wishlist_index.php */
function showsendwishlistmodal() {

    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}


/* Envoie le formulaire de la lightbox wishlistavec gestion des erreurs
   Utilisation : wishlist_index.php */
function sendWishListForm() {

    $("#sendWishListSubmit").css({display: 'none'});
    $('#wrapper_loader_send').css({display: 'block'});
    var content_id = $("#send_wishlist_ajax");
    var content;
    if ($('#sendWishListToMails').length && $('#sendWishListToMessage').length && $('#sendWishListYourName').length && $('#sendWishListYourMail').length) {

        var to_mails = $('#sendWishListToMails').val();
        var to_message = $('#sendWishListToMessage').val();
        var your_name = $('#sendWishListYourName').val();
        var your_mail = $('#sendWishListYourMail').val();
        var token_security = $('#token_security_wishlist').val();
        var captcha = $('#g-recaptcha-response').val();

        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist_form') + '?to_mails=' + to_mails + '&to_message=' + to_message + '&your_name=' + your_name + '&your_mail=' + your_mail + '&token_security=' + token_security + '&g-recaptcha-response=' + captcha
        }).done(function (response) {

            $('.zone_text').css({border: ''});

            if (response.split(',')[0] == 'ok') {

                var s_adresse = response.substr(3);

                var sing = (response.split(',').length > 2) ? 'sendfriend_ok_plur' : 'sendfriend_ok_sing';

                content = '<div>' + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=' + sing) + '</div>';
                content += '<p style=\"margin-top: 10px;margin-bottom: 30px;padding:0;text-align:center;\">' + s_adresse + '</p>';

                $('html, body').animate( { scrollTop: $('#wishlistform').offset().top - 50 }, 500 ); // Go
                setTimeout(function() {window.location.reload(true)}, 2500);
            }
            if (response == '1' || response == '2') {

                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_mail_friends_invalid') + "</p>";
                $('#sendWishListToMails').addClass('inputErr');
            } else {

                $('#sendWishListToMails').removeClass('inputErr');
            }
            if (response == '3') {

                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_no_message') + "</p>";
                $('#sendWishListToMessage').addClass('inputErr');
            } else {

                $('#sendWishListToMessage').removeClass('inputErr');
            }
            if (response == '4') {

                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_no_name') + "</p>";
                $('#sendWishListYourName').addClass('inputErr');
            } else {

                $('#sendWishListYourName').removeClass('inputErr');
            }
            if (response == '5' || response == '6') {

                $('#sendWishListYourMail').addClass('inputErr');

                if (response == '5') {
                    content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_no_my_mail') + "</p>";
                } else {
                    content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_my_mail_invalid') + "</p>";
                }
            } else {

                $('#sendWishListYourMail').removeClass('inputErr');
            }

            if (response == 'token_error') {
                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=error_token_security') + "</p>";
            }

            if (response.split(',')[0] == '7') {
                var s_adresse = response.substr(3);
                var sing = (response.split(',').length > 2) ? 'sendfriend_no_ok_plur' : 'sendfriend_no_ok_sing';
                content = '<div>' + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=' + sing) + '</div>';

            }
            if (response.split(',')[0] == '7' || response.split(',')[0] == 'token_error' || response.split(',')[0] == 'ok') {
                content_id = $("#sendfriend_alert_mail");

                $('#sendfriend_alert_mail').css({display: 'block', opacity: 0})
                $('#wrapper_sendwishlist').addClass('sended').css({
                    display: 'block',
                    opacity: 1
                }).animate({opacity: 0}, function () {
                    $('#wrapper_sendwishlist').css({display: 'none'});
                    $('#sendfriend_alert_mail').animate({opacity: 1}, function () {
                        $('#sendfriend_alert_mail').css({display: 'block'});
                    });
                });
            } else {
                $('#sendfriend_form_buttons').removeClass('loading');
            }

            content_id.html(content).css({display: 'block'});

        });

    }
    return false;
}

/* Utiliser pour ajouter un magasin dans ses favoris (boutique_details) */
function addToMyFavorites() {

    var is_favorite_elem = document.getElementById('is_favorite');

    var self = $(this);
    var magasin_id = self.attr('data-magasinid');
    var client_id = self.attr('data-clientid');
    var action = self.attr('data-action');

    // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
    if (action == 'destroy') {
        var act = 'del';
    } else {
        // On est dans store_details et on doit recupérer le statut actuel du magasin pour savoir si on doit le supprimer ou l'ajouter
        if (is_favorite_elem.value == '1') {
            var act = 'del';
        } else {
            var act = 'add';
        }
    }

    $.ajax({
        url: path_relative_root + 'ajax_manage_favorites_stores.php',
        type: 'post',
        dataType: 'html',
        data: 'act=' + act + '&magasin_id=' + magasin_id,
        success: function (response) {

            if (response) {

                // Destruction du bloc
                if (action == 'destroy') {
                    self.slideUp('slow', function () {
                        self.remove();
                    });
                } else {
                    if (act == 'add') {
                        var libelle = '<span>' + translate('delete_to_favorites') + '</span>';

                        is_favorite_elem.value = 1;
                    } else {
                        var libelle = '<span>' + translate('add_to_favorites') + '</span>';

                        is_favorite_elem.value = 0;
                    }
                    self.html(libelle);
                }
            }
        }
    });
}
